.successpf-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successpf-title {
    font-size: 36px;
    margin-bottom: 10px;
    padding: 10px;
}

.successpf-subtitle {
    font-size: 26px;
    margin-bottom: 20px;
    padding: 10px;
    font-weight: bold;
}

.successpf-info {
    font-size: 20px;
    margin-bottom: 5px;
    padding: 10px;
    font-weight: bold;
}

.successpf-link {
    display: block;
    font-size: 30px;
    margin-top: 20px;
    text-decoration: none;
    color: red;
}

.successpf-link:hover {
    text-decoration: underline;
}

.successpf-container ul {
    list-style-type: none;
    padding: 10;
}

.successpf-container ul li {
    margin-bottom: 10px;
    padding: 10px;
}

.successpf-container ul li p {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    /* Elimina el margen predeterminado de los párrafos dentro de los elementos de lista */
}