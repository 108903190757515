.divAuth,
.divNotFound,
.divSpinner,
.divBadConn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divAuth h1,
.divAuth h3,
.divNotFound h1 {
    margin: 30px 0;
    padding: 0;
}

.divAuth h1 {
    color: #f00;
    font-weight: bold;
    font-size: 50px;
    margin: 60px 0 20px 0;
}

.divAuth span {
    font-size: 60px;
}

.divAuth button {
    margin: 30px 0;

}

.divNotFound span,
.divBadConn span {
    font-size: 200px;
    font-weight: bold;
    margin-top: -40px;
}

.divNotFound h2 {
    font-size: 20px;
    margin: -10px 0 40px;
}

.divPolicy {
    margin: 40px;
    text-align: justify;
}

#spinner {
    display: inline-block;
    width: 200px;
    height: 200px;
    vertical-align: -.125em;
    border: 20px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.2s linear infinite spinner-border;
    margin: 20px 0;
}

.divSpinner span {
    font-size: 30px;
    margin: 20px 0;
}

.divBadConn {
    margin: 40px 0;
}

.divBadConn a {
    font-size: 30px;
    margin: -20px 0;
}

#divError {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin: 40px;
    background-color: #eee;
    border-radius: 10px;
    margin-top: 100px;
}

#divError svg {
    width: 100px;
    margin: 0px 0 30px;
}

#divBadGateway {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    margin: 50px 0;
}

#divBadGateway>h5,
#divBadGateway>h1 {
    margin: 0 0 30px 0;
}

.spinner-container {
    display: flex;
    justify-content: center;
}

.spinner-loader {
    width: 125px;
    height: 125px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
}

.spinner-loader::before,
.spinner-loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
}

.spinner-loader::before {
    border-color: #f00 #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
}

.spinner-loader::after {
    margin: 8.9px;
}

@keyframes spinner-e04l1k {
    100% {
        transform: rotate(1turn);
    }
}