.background {
    width: 100%;
    height: 100%;
}

.stretch {
    width: 100%;
    border-radius: 20px;
}

.imagenesBody {
    width: 100% !important;
}

.imagenesBody2 {
    width: 90% !important;
    margin-left: 5%; /* Margen izquierdo */
    margin-right: 5%;
}

#divDestacados {
    display: flex;
    flex-direction: column;
}

#divDestacados>h2 {
    margin: 40px 0 0 0;
    text-align: center;
}

#divRenderDestacados {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.renderItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 15%;
    margin: 40px auto;
    padding: 10px;
    border-radius: 20px;
    list-style-type: none;
    justify-content: space-around;
    box-shadow: 8px 0px 32px 0px rgb(0 0 0 / 23%);
}

.renderItem li ul {
    text-align: center;
    padding-left: 0px !important;
}

.renderItemCenter ul {
    text-align: center;
    padding-left: 0px !important;
}


#divRenderDestacados img {
    margin-bottom: 30px;
    width: 90%;
    object-fit: scale-down;
    height: 90%;
    background: #fff;
    border-radius: 20px
}

.renderItem ul:nth-child(2) {
    font-size: 15px;
}

.renderItem ul:nth-child(3) {
    font-size: 20px;
    height: 80px;
}

.renderItem button {
    padding: 10px 25px;
    font-weight: bold;
}

/* ============================================================================================== */

.btnWebinars,
.btnCatalogo {
    max-width: 500px;
    margin: 20px 0px;
    width: auto;
    height: auto;
}

.contWebinarCatalogo {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

#inputFindProduct {
    display: flex;
    flex-direction: row;
}

#inputFindProduct>input {
    outline: none;
    border-radius: 10px;
    width: 300px;
    margin-right: 40px;
}

#btnCerrar {
    display: inline-flex;
    background: red;
    color: white;
    width: 30px;
    height: 30px;
    font-size: 180%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    /* margin: 5px -40px; */
    margin-right: 30px;
    display: none !important;
}

.btn-close {
    opacity: 1 !important;
}

#paginarBusqueda {
    margin: 0;
    background-color: #fff;
    opacity: 0.9;
    border-radius: 0.375rem 0.375rem 0 0;
    /* width: 600px; */
}

.divListItem {
    display: flex !important;
    flex-direction: row;
}

.divListItem:hover {
    background-color: #ddd;
}

.imgThumb {
    max-width: 50px;
    margin-right: 10px;
}

.divListItemData {
    display: flex;
    flex-direction: column;
}

.aliasItem {
    font-size: 70%;
    font-weight: bold;
}


.linkToItem {
    text-decoration: none !important;
    padding: 0 !important
}

@media only screen and (max-width : 768px) {

    .renderItem {
        max-width: 80%;
        margin: 10px auto
    }

    .renderItem ul:nth-child(2) {
        font-size: 25px;
    }

    .renderItem ul:nth-child(3) {
        font-size: 15px;
        height: 40px;
    }

    #divDestacados {
        display: none;
    }

    #divError {
        display: none;
    }

    #inputFindProduct>input {
        width: 100%;
        margin-bottom: 10px;
    }

    .btnCatalogo, .btnWebinars {
        max-width: 400px;
        margin: 20px 0px;
        width: auto;
        height: auto;
    }
}