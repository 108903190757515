.translate-button {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
}


.btnLogueado {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 13px;
    z-index: 10;
}


.btnLogueado span {
    color: #fff;
    align-self: center;
}

.btnLogueado2 {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    flex-wrap: wrap;
}

.btnLogueado2 div,
.btnLogueado2 span {
    margin: 0 10px;
}

@media(max-width:992px) {
    .btnLogueado2 {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}