.cartWidget {
    width: 2vw;
    margin: 0 2vw 0 0;
}

.bntAddMinus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh
}

.bntAddMinus button {
    color: #fff;
    border-style: none;
    height: 4vh;
    width: 4vh;
    border-radius: 5px;
}

.sumar {
    background-color: white;
    margin-left: 0px;
    border-radius: 5px;
    padding: 0;
}

.restar {
    background-color: white;
    margin-left: 0px;
    border-radius: 5px;
    padding: 0;
}

.botonesAll {
    width: 100% !important;
}

.bntAddMinus input {
    height: 4vh;
    width: 4vh;
    border-radius: 50px;
    text-align: center;
    margin: 0 1vh;
    font-weight: bold;
    border-style: none;
}

.addToCart {
    display: flex;
    justify-content: center;
}

.addToCart button {
    color: #fff;
    border-style: none;
    background-color: #1f871f;
    padding: .5vh 2vh;
    border-radius: 50px;
}

.contadorCart {
    display: inline-flex;
    background: red;
    color: white;
    font-weight: bold;
    width: 30px;
    height: 30px;
    font-size: 140%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin: 1vw
}

a {
    text-decoration: none !important;
}

.linkItem {
    color: #000000;
    text-decoration: underline;
}

.linkItem:hover {
    color: red;
    font-weight: bold;
}

.tableCart {
    text-align: center;
}

.tableCart thead tr th span:nth-child(2) {
    display: none;
}

.table td,
.table th {
    vertical-align: middle;
}

.cartRenderizado h1 {
    width: 60%;
    margin: 0 20%;

}

.itemInCart {
    background-color: #ddd;
    padding: 10px;
    border-radius: 20px;
    width: 0 60%;
    margin: 10px 20%;
}

.cartRenderizado h1 {
    text-align: center;
    padding: 10px 0;
}

.modificarUnidades {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.eliminarUnidades {
    background-color: white;
    border-radius: 5px;
}

.fa-minus,
.fa-plus,
.fa-circle-x,
.fa-trash-can {
    color: #fff;
    font-size: 20px;
}

.tableFooter {
    font-weight: bold;
}

.btnFinalizarCompra {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btnConfirmarCompra {
    width: 300px;
    height: 50px;
    background-color: #c24040 !important;
}

.btnFinalizarCompra button {
    background-color: #212529;
    color: #fff;
    padding: 5px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.fa-basket-shopping {
    margin-right: 10px;
    font-size: 20px;
}

/*BTN POST ADD*/
.btnPostAdd {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btnSeguir,
.btnFinalizar {
    margin: 0 10px;
}

.btnSeguir {
    background-color: #062a4f;
}

.btnIrAlCarrito {
    position: relative;
    background-color: #fff;
    /* border-radius: 30px; */
    /* box-shadow: 5px 10px 20px 0 rgba(0,0,0,.11); */
    padding: 10px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    color: #000;
    /* font-weight: 500; */
    border: 2px solid #000;
    font-size: 13px;
    transition: all .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    width: 220px;
    text-transform: uppercase;
}

.btnIrAlCarrito:hover::before {
    content: "";
    background-color: rgb(0, 0, 0);
    color: #fff;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 300ms ease-in-out;
    display: inline-block;
}

#btnIrAlCarrito2 {
    font-size: 12px;
    padding: 2px 0px !important;
    width: 100%;
    height: 3em;
    border-color: transparent;
    background: #0f0c0c !important;
    color: white !important;
    font-weight: 900;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    text-transform: uppercase;
}


#btnIrAlCarrito2:hover {
    box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
    background: #4141416c !important;
}

.btnIrAlCarrito:hover::before,
#btnIrAlCarrito2:hover::before {
    width: 100%;
    color: #fff;
}

.btnIrAlCarrito2:hover,
#btnIrAlCarrito:hover {
    color: #fff;
}

.carritoVacio {
    text-align: center;
    height: 55vh;
}

.btnVolver {
    width: 300px;
    border: none;
}

.btnVolver:hover {
    background-color: red !important;
    outline: red !important;
    border: none;
    transition: 1s ease all;
}

@media only screen and (max-width : 768px) {
    .cartWidget {
        width: 8%;
        margin: 2vw;
    }

    .table>:not(caption)>*>* {
        padding: .1rem !important;
    }

    .tableCart thead tr th span:first-child {
        display: none;
    }

    .tableCart thead tr th span:nth-child(2) {
        display: block;
    }

    .modificarUnidades {
        flex-direction: column;
    }
}

/* Estilos para pantalla grande */
@media screen and (min-width: 501px) {
    .mobile-table {
        display: none;
    }
}

/* Estilos para pantalla pequeña */
@media screen and (max-width: 500px) {
    .desktop-table {
        display: none;
    }

    .modificarUnidades {
        flex-direction: row;
    }

    .mobile-table {
        gap: 12px;
    }
}