/*
.formulario {
    margin: 0px auto;
    padding: 30px;
    width: 30%;
    border-radius: 10px;
    text-align: center;
    background-color: rgba(51, 51, 51, 0.381);
    box-shadow: 0px 10px 10px -6px black;
}

.formulario h2 {
    text-align: center;
    margin: 20px;
}

.textForm{
    width: 80%;
    height: 30px;
    border: none;
    outline: none;
    margin: 10px;
    border-radius: 10px;
    padding: 15px;
}

.formAlignCenter{
    align-items: center;
}


.butn {
    width: 80%;
    height: 60%;
    margin: 20px 0;
    background: #333;
    border-radius: 20px;
    font-size: 20px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
}

.butn:hover {
    background: rgba(51, 51, 51, 0.564);
    font-size: 20px;
    transition: 0.5s;
}

.ingreseCV {
    height: 5%;
    max-width: 100%;
}

.divErrorForm{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 50px auto;
    text-align: center;
}

.divErrorForm h4{
    margin: 30px 0;
}

@media only screen and (max-width: 1000px) {
    .formulario {
        width: 85%;
    }

    .textForm {
        width: 85%;
        height: 40px;
    }

    .butn {
        width: 95%;
    }


}
*/

/* Estilos para el formulario */
.form-box-register-trabaja {
    background: linear-gradient(135deg, #0072ff, #00c6ff);
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.logo-trabaja {
    color: #fff;
}

/* Oculta el icono de fecha predeterminado */
input[type="date"]::-webkit-calendar-picker-indicator-trabaja {
    display: none;
}

@keyframes moveGradient {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.content-trabaja {
    background-image: linear-gradient(45deg, #FF5733, #001f3f, #6A7283);
    padding: 22px;
}


/* Estilos para la sección de inicio de sesión y registro */
.section-login-register-trabaja {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;

    background-position: center;
    background-size: cover;
}



.form-box-register-trabaja {
    position: relative;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 2px solid #fff;
    backdrop-filter: blur(15px);
}

.title-login-register-trabaja {
    color: #fff;
    text-align: center;
}

.bandera-trabaja {
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ingreseCV-trabaja {
    display: none;
    /* Oculta el input de archivo nativo */
}

.custom-file-upload {
    background-color: #023047;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.custom-file-upload:hover {
    background-color: #00192d;
}



.login-title-register-trabaja {
    font-size: 1.5em;
    color: #fff;
    text-align: center;
}

.logo-solution-trabaja {
    padding: 12px;
}

.input-box-login-register-trabaja {
    position: relative;
    margin: 30px;
    width: 500px;
    border-bottom: 2px solid #fff;
}

.input-box-login-register-trabaja label {
    position: absolute;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
    font-weight: bold;
}

.custom-file-upload {
    border: 2px solid #ff5733;
    /* Cambia el color del borde del botón */
    color: #ff5733;
    /* Cambia el color del texto del botón */
    background-color: transparent;
    /* Hace que el fondo del botón sea transparente */
    text-align: center;
    /* Centra el texto del botón */
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}


.input-box-login-register-trabaja input:focus~label,
.input-box-login-register-trabaja input:valid {
    top: -10px;
}

.input-box-login-register-trabaja input {
    width: 100%;
    top: -1px;
    height: 50px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0 35px 5px;
    color: #fff;
}

.input-box-login-register-trabaja .icon-login-trabaja {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}

/* Cambios en el CSS */
.input-box-login-register-trabaja input[type="file"] {
    display: none;
}

.forget-register-trabaja {
    margin: -15px 0 15px;
    font-size: .9em;
    color: #fff;
    display: flex;
    justify-content: center;
}

.forget-register-trabaja label input {
    margin-right: 3px;
}

.forget-register-trabaja label a {
    color: #fff;
    text-decoration: none;
}


.forget-register-trabaja label a:hover {
    text-decoration: underline;
}

.button-login-screen-trabaja {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 17px;
    background-color: #ff5733;
    color: #fff;
}

.button-login-screen-trabaja:hover {
    background-color: #2667ff;
}

.register-register-trabaja {
    font-size: .9em;
    color: #fff;
    text-align: center;
    margin: 25px 0 10px;
}

.register p a-trabaja {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

.register-register-trabaja p a:hover {
    text-decoration: underline;
}

.register-text-trabaja:hover {
    text-decoration: underline;
}

.captcha-container-trabaja {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.text-register-title-trabaja {
    margin-top: 10px;
    text-align: center;
    text-decoration: underline;
    color: #effffa;
    font-weight: bold;
    font-size: 14px;
}

.input-box-login-register-trabaja input {
    color: #023047;
}

.logo span {
    color: #ef233c;
}

.white-text-input-trabaja {
    color: #fff !important;
}

@media only screen and (max-width : 1000px) {
    .input-box-login-register-trabaja {
        position: relative;
        margin: 30px;
        width: 300px;
        border-bottom: 2px solid #023047;
    }

    .form-box-register-trabaja {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: 2px solid #023047;
        border-radius: 20px;
        backdrop-filter: blur(15px);
    }

    .logo-trabaja {
        font-size: 22px;
        text-align: center;
    }

    .button-login-screen-trabaja {
        width: 80%;
        margin-left: 33px;
        text-align: center;
    }

    .text-register-title-trabaja {
        font-size: 10px;
    }
}