.tools-h1 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 10px;
}

.tools-h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    text-align: left;
    margin-left: 10px;
    margin-right: 17px;
}

.rma-conditions {
    height: 50vh;
}

.tools-h5 {
    margin-left: 10px;
    margin-right: 10px;
}

.tools-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tools-li {
    font-size: 18px;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #fff;
}

.tools-container {
    padding-left: 10%;
    margin-bottom: 22px;
}

.tools-ul .tools-li a {
    color: red;
    font-size: 20px;
}

.tools-ul .tools-li a:hover {
    text-decoration: underline;
}

.links-container {
    display: flex;

}

.tools-container .links-container a {
    color: red;
    font-size: 18px;
    margin-left: 5px;
    margin-top: 10px;
    font-weight: 400;
}

.tools-container .links-container a:hover {
    text-decoration: underline;
}

.ubiquiti-container {
    display: flex;
    margin-right: 10px;
}