.img_tamano {
    max-width: 800px;
    max-height: 500px;
}

#carouselDetalles{
    max-height: 600px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgDetalles > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imagenIlustrativa{
    font-size: 12px;
    color: gray;
    margin-left: 5%;
}


/* #carouselDetalle > .carousel-control-next, #carouselDetalle> .carousel-control-prev{
    background-color: #9999996b !important;
} */

@media screen and (max-width:600px) {
    .img_tamano {
        max-width: 600;
        max-height: 300;
        overflow: hidden;
    };
  }