/* DatosPersonales.css */

.datos-personales-container {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.datos-personales-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.tabla-datos {
    width: 100%;
    border-collapse: collapse;
}

.tabla-datos th,
.tabla-datos td {
    padding: 12px 16px;
    text-align: left;
    border: 1px solid #e0e0e0;
}

.tabla-datos th {
    background-color: #333;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tabla-datos tr:nth-child(even) {
    background-color: #f2f2f2;
}

.tabla-datos tr:hover {
    background-color: #ddd;
    cursor: pointer;
}

.tabla-datos h5 {
    font-size: 18px;
    color: #333;
}

/* Añade más estilos según tus preferencias */