.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0px !important;
    width: 60px;
    height: 60px;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsapp-button:hover {
    background-color: #128c7e;
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bd-violet-bg);
    --bs-tooltip-color: var(--bs-white);
}