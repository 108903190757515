.divCheckout {
    display: flex;
    flex-direction: column;
    margin: 0 20%;
    align-items: center
}

.divCheckout h1 {
    padding: 10px 0;
    color: #ef233c;
}

.cardBody h3 {
    padding: 20px 0 0 0;
    text-align: center;
    color: #0077b6;
}

.aCoordinar {
    color: #0077b6;
}

.divAccordion {
    width: 60vw;
}

.divCheckout button {
    width: auto;
    border-radius: 10px;
    margin: 10px 0;
}

.fa-circle-chevron-left,
.fa-circle-check {
    margin-right: 10px;
}

.card-header {
    width: 100% !important;
    background-color: #212529 !important;
}

.form-check-label {
    color: #fff;
}

.cardBody {
    width: 100%;
}

.cardBody>form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.datosForm {
    margin-left: 20px;
    width: 80% !important;
}

.opcionesForm {
    width: 90%;
}

.opcionesForm label {
    display: block;
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

.opcionesForm select {
    width: 100%;
    padding: 7px;
    box-sizing: border-box;
}


.opcionesForm h6 {
    margin-left: 20px;
}



.opcionesForm select option {
    padding: 10px;
}

.cardBody form input:nth-child(13) {
    width: 80%;
}

.cardBody form button {
    margin: 10px auto;
}

/* .btn-success{
    margin-left: 10px;
} */

.divSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
    text-align: center;
}

.divSuccess h4 {
    margin: 20px 0 10px;
}

.divSuccess button {
    margin: 10px 0 60px;
}

.facturacion-container-checkout-estilos {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    /* Espaciado entre columnas */
}

.facturacion-datos-checkout-estilos,
.facturacion-entrega-checkout-estilos {
    flex: 1;
}

.facturacion-datos-checkout-estilos h3,
.facturacion-entrega-checkout-estilos Form.Label {
    color: #475569;
    font-weight: bold;
}

.facturacion-datos-checkout-estilos h6 {
    color: #9ca3af;
}

.stepper-buttons{
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-bottom: 15px;
}

/* Estilos para móviles */
@media (max-width: 768px) {
    .facturacion-container-checkout-estilos {
        flex-direction: column;
        /* Colocar las columnas verticalmente */
    }

    .facturacion-datos-checkout-estilos {
        margin-bottom: 20px;
        /* Espaciado entre las dos secciones */
    }
}


@media only screen and (max-width : 990px) {

    .divAccordion,
    .cardBody {
        width: 100vw;
        margin: 0;
    }

    .divCheckout {
        margin: 0;
    }

    .divCheckout button {
        width: auto;
    }

    .cardBody form input {
        width: 100%;
    }

    .cardBody h3,
    .cardBody form h3 {
        text-align: center;
    }


    .cardBody form input:nth-child(3),
    .cardBody form h3 {
        width: 100%;
    }

    .cardBody form input:nth-child(7),
    .cardBody form input:nth-child(9) {
        width: 100%;
    }

    .cardBody form input:nth-child(8),
    .cardBody form input:nth-child(10) {
        width: 100%;
    }

    .opcionesForm {
        flex-direction: column;
        margin-left: 15px;
    }

    .opcionesForm label {
        width: 100%;
    }

    .opcionesForm select {
        width: 100%;
    }
}

.botonProcesarCompra {
    width: 100% !important;
    height: 50px !important;
}