.book-container {
    margin: 0;
    padding: 0;
    height: 130vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Roboto", sans-serif;
}

.animate-fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.btnCatalogo {
    max-width: 500px;
    margin: 20px 0px;
    width: auto;
    height: auto;
}


.book input {
    display: none;
}

.book img {
    width: 100%;
    height: 100%;
}

.book {
    display: flex;
}

#cover {
    width: 600px;
    height: 800px;
}

.flip-book {
    width: 600px;
    height: 800px;
    position: relative;
    perspective: 1500px;
}

.flip {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    color: #000;
}

.current-page {
    transform: rotateY(-180deg);
}

p {
    font-size: 14px;
    line-height: 24px;
}

.front {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0;
    box-shadow: inset 20px 0 50px rgba(0, 0, 0, 0.5) 0 2px 5px rgba(0, 0, 0, .5);
}

.back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    background-color: #000;
}

#p1 {
    z-index: 8;
}

#p2 {
    z-index: 7;
}

#p3 {
    z-index: 6;
}

#p4 {
    z-index: 5;
}

#p5 {
    z-index: 4;
}

#p6 {
    z-index: 3;
}

#p7 {
    z-index: 2;
}

#p8 {
    z-index: 1;
}



.back-btn,
.next-btn {
    position: absolute;
    bottom: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.icon-container {
    background-color: #d9d9d9;
    padding: 8px;
    display: inline-block;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
}

.back-btn {
    left: -40px;
    color: #393d3f;
}

.next-btn {
    right: -40px;
    color: #393d3f;
}

@media (max-width: 1400px) {
    .book-container {
        height: 150vh;
    }

    #cover {
        width: 500px;
        height: 600px;
    }

    .flip-book {
        width: 500px;
        height: 600px;
        position: relative;
        perspective: 1500px;
    }
}

@media (max-width: 768px) {
    .book-container {
        height: 80vh;
        width: 100%;
    }

    .book {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;
    }

    #cover,
    .flip-book {
        width: 250px;
        height: 400px;
    }

    .icon-container {
        background-color: #d9d9d9;
        padding: 8px;
        display: inline-block;
        width: 70%;
        height: 70px;
        display: flex;
        align-items: center;
    }

    .back-btn {
        bottom: 120px;
        left: -25px;
        color: #393d3f;
    }

    .next-btn {
        right: -25px;
        bottom: 120px;
        color: #393d3f;
    }
    .btnCatalogo {
        max-width: 400px;
        margin: 20px 0px;
        width: auto;
        height: auto;
    }
}
