/* NuestraEmpresa.css */
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
.about-us {
    padding: 50px;
    text-align: center;
    background: #fff;
    /* Fondo blanco */
    color: #00072d;
    /* Texto rojo */
    opacity: 0;
    /* Inicialmente oculto */
    transform: translateX(-100px);
    /* Inicialmente desplazado a la izquierda */
    animation: slideInRight 1s ease-in-out forwards;
    /* Animación de deslizamiento desde la izquierda */
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(-100px);
        /* Inicio de la animación: completamente desplazado a la izquierda */
    }

    100% {
        opacity: 1;
        transform: translateX(0);
        /* Final de la animación: sin desplazamiento */
    }
}

h2 {
    font-family: 'Manrope', sans-serif!important;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 35px;
    text-align: left;
}

.text-content{
    font-size: 22px;
    line-height: 1.5;
    font-family: 'Manrope', sans-serif;
    text-align: left;
}

.image-content img {
    border: 2px solid #f15156;
    /* Borde rojo */
    border-radius: 10px;
    /* Radio de borde */
    margin: 10px;
    padding: 10px;
    /* Espaciado entre imágenes */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Sombra */
    opacity: 0;
    /* Inicialmente oculto */
    transform: translateX(-100px);
    /* Inicialmente desplazado a la izquierda */
    animation: slideInRight 1s ease-in-out forwards;
    /* Animación de deslizamiento desde la izquierda */
}

.cards-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.cards-empleados {
    background-color: #f9f9f9;
    margin: 10px;
    border-radius: 15px;
    width: 260px;
    transition: box-shadow 0.2s;
    min-height: 400px;
    box-shadow: 5px 5px 37px 10px rgb(0 0 0 / 13%);
}


.categorias-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cards-empleados:hover {
    transition: transform 0.2s;
}

.cards-empleados-shadow:hover{
    /* box-shadow: 10px 20px 59px rgb(255 7 7 / 51%); */
    transition: box-shadow 0.2s;
}

.cards-empleados-image-container {
    width: 100%;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.cards-empleados-image {
    width: 70%;
    background-color: transparent;
    margin: 20px;
    border-radius: 90px;
    /* Establece el fondo de la imagen como transparente */
    transition: transform 0.2s;
}

/* .cards-empleados-image:hover {
    transform: scale(1.2);
    transition: transform 0.5s;
} */

.cards-empleados-content {
    padding: 16px;
}

.cards-empleados-content p {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cards-empleados h2 {
    font-size: 20px;
    margin-bottom: 8px;
}

.cards-empleados p {
    font-size: 13px;
    margin-bottom: 12px;
}

.cards-empleados-colored-background {
    background-color: #ef233c;
    padding: 4px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cards-empleados-colored-background p {
    font-size: 15px;
    font-weight: bold;
}

.staffTitle {
    color: white;
    text-align: center;
    font-weight: bold;
}

.staff{
    color: #212529;
}

/* Estilos para centrar imágenes en dispositivos móviles */
@media (max-width: 768px) {
    .image-content {
        display: flex;
        flex-direction: column;
        /* Alinea imágenes y texto verticalmente */
        align-items: center;
        /* Centra horizontalmente */
        justify-content: center;
        /* Centra verticalmente */
        text-align: center;
        /* Alinea texto al centro */
    }
}