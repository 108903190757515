.accordion {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
  }
  
  .accordion-content {
    padding: 10px;
  }
  
  .icon {
    width: 10px;
    height: 10px;
    border-style: solid;
    border-width: 1px 1px 0 0;
    display: inline-block;
    margin-left: auto;
    transform: rotate(45deg);
  }
  
  .text-center {
    text-align: center !important;
  }

  .icon-expanded {
    transform: rotate(-135deg);
  }
  
  .icon-collapsed {
    transform: rotate(45deg);
  }
  