@media (min-width: 400px) {
    .mobileImg{
       display: none;
    }
   }

@media (max-width: 400px) {
    .desktopImg{
       display: none;
    }
}

.mobileImg {
    margin-top: 150px;
}

.contenedorKodak {
    display: flex;
    justify-content: center;
}

.imgKodak {
    width: 60%;
}

   