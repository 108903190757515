object {
    height: 600px;
    width: 800px;
}

@media (max-width:584px) {
    object {
        height: 350px;
        width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}