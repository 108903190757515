.progress-bar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.progressBarrr {
  display: flex;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #666;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
}

.acuerdoUsuario {
  display: flex;
  justify-content: center;
}

.acuerdoUsuarioAgree {
  display: flex;
  justify-content: center;
  text-align: center;
}

.agreeButton {
  text-align: center;
  margin-top: 20px;
}

.backButtonContainer {
  display: flex;
  text-align: center;
  justify-content: center;

}

.backButton {
  width: 10%;
  background-color: #14213d;
  border-radius: 12px;
  margin-bottom: 5px;

}

.nextButton {
  margin: 10px;
}

/* button {
  cursor: pointer;
  color: white;
} */

.circulitos {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
}

.circulazo {
  display: flex;
  justify-content: 'center';
  text-align: center;
}

.circle.active {
  background-color: #14213d;
  color: #fff;
}

.progress-bar-indicator {
  margin-bottom: 10em;
  display: flex;
  height: 0.5em;
  background-color: #14213d;
}

.check {
  width: 25px;
  height: 25px;
  margin-right: 7px;
}

.form-registro {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
} 

.circulitos-h2 {
  margin-top: 1em;
  text-align: center;
}

input[type="email"],
input[type="password"] {

  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #0088cc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
} */

.btn-back {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #14213d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-bottom: 3px;
}

.bg-boton {
  background-color: #0088cc;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.backButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.btn-next {
  background-color: #14213d;
}

.circulitos-h2 {
  text-align: center;
}

@media (max-width:584px) {
  .circulitos {
    display: flex;
    margin-top: 22px;
    margin-bottom: 20px;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-items: flex-end;
  }
}