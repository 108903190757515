.stepper-profile-dni {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; 
  gap: 10px; 
}


.step-profile-dni {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.circle-profile-dni {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #9ca3af;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.circle-profile-dni.active {
  background-color: #334155;
}

.circle-profile-dni.completed {
  background-color: #0b905f;
}

.icon-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #bae6fd;
  margin-bottom: 30px;
  transition: background-color 0.3s ease;
}

.icon-upload-container:hover {
  background-color: #93c5fd;
}

.label-profile-dni {
  font-size: 14px;
  font-weight: bold;
}

.alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}