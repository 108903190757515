.headerNuevo {
    background-color: #212529;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: relative;
}


.headerNuevo::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    border-bottom: 1px solid #e11d48;
}


.rowNuevo {
    justify-content: space-around;
}

.logoNuevo {
    max-width: 150px;
    height: auto;
}

.searchInputNuevo {
    border-radius: 5px;
}

.searchButtonNuevo {
    padding: 0 20px;
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}


.iconNuevo {
    color: #ffffff;
    cursor: pointer;
}

.accountTextNuevo {
    font-size: 14px;
    margin-top: 5px;
    color: #ffffff;
    cursor: pointer;
}

.dropdownToggle {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.dropdownMenu {
    background-color: #343a40;
    border: none;
}

.iconWithUser {
    color: #0ea5e9;
    cursor: pointer;
}

.iconWithoutUser {
    color: gray;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.iconWithoutUser:hover {
    color: #be123c;
    cursor: pointer;
}