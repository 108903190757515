.custom-card {
    width: 200px;
    height: 350px;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.custom-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    cursor: pointer;
}

.custom-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: #ced4da;
}

.custom-content {
    padding: 20px;
}

.relacionados-container {
    margin-bottom: 2em;
}

.map-destacados {
    display: flex;
    justify-content: center;
    margin-right: 2em;
}

.relacionados-container-mobile {
    display: none;
}

.map-destacados-mobile {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
}

@media (max-width: 768px) {
    .map-destacados {
        display: none;
    }

    .map-destacados-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
    }

    .relacionados-container-mobile {
        display: block;
        margin-bottom: 2em;
    }

    .relacionados-container {
        display: none;
    }
}