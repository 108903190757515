/* #buttonCard > #addToCart{
    border-radius: 4px;
    padding: 24px 10px; 
    outline: 0;
    width: 100%;
} */

#buttonCard> .bntAddMinus{
    margin: 10px 0;
}

#buttonCard > .btnPostAdd > #btnFinalizar{
    margin: 10px 0;
    border-radius: 4px;
    padding: 24px 10px; 
    outline: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

