.search-form {
    position: relative;
    width: 100%;
}

.search-form.active {
    width: 100%;

}

.search-bar-container {
    display: flex;
    align-items: center;
}

.search-input-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input.input-active::placeholder {
    color: #ccc;
    transition: color 0.3s ease-in-out;
}

.search-icon {
    padding: 10px;
    color: #aaa;
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 2;
}

.suggested-results {
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 750px;
    overflow-y: auto;
    z-index: 10;
    display: none;
    top: 100%;
    left: 0;
}

.show {
    display: block;
}

.suggested-result {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.suggested-result:hover {
    background-color: #f0f0f0;
}

.suggested-text {
    display: flex;
    align-items: center;
}

.suggested-text img {
    width: 60px;
    height: 50;
    object-fit: cover;
    margin-right: 10px;
    padding: 0.2em;
    border: 1px solid #9ca3af;
    border-radius: 3px;
}

.flex-container {
    display: flex;
    align-items: center;
    margin: 2px;
    gap: 12px;
}

.icon-lupa {
    font-size: 16px;
    color: #374151;
}

.suggested-results .divider {
    width: 90%;
    margin: 10px auto;
    border-top: 1px solid #1e293b;
}


.suggested-results h6 {
    margin: 12px;
    color: #52525b;
}

.active .page-link {
    background-color: #007bff !important;
    color: white;
}

.ver-product-title {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    justify-content: center;
    width: 100%;
    color: #4b5563;
    transition: color 0.3s ease, font-weight 0.3s ease;
}

.ver-product-title:hover {
    color: #52525b;
    font-weight: bold;
    cursor: pointer;
}

.text-ver-producto-link {
    margin: 0;
    font-size: 0.9em;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
}

.text-ver-producto-link:hover {
    color: #0056b3;
    text-decoration: underline;
}