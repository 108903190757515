.procesar-compra-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;
}

.procesar-compra-text {
    font-size: 1.5rem;
}

.procesar-compra-text-second {
    font-size: 1rem;
    text-align: center;
}

.boton-estilizado {
    width: 300px;
    padding: 15px;
    border: 2px solid red;
    border-radius: 30px;
    background-color: transparent;
    color: red;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.boton-estilizado:hover {
    background-color: red;
    color: white;
}

/* ProcesarCompraButton.css */

Estilos generales
.procesar-compra-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.top-section {
    display: flex;
    width: 100%;
}

.left-side, .right-side {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
    .top-section {
        flex-direction: column;
    }

    .left-side, .right-side {
        width: 100%;
    }
}
