/* Estilos generales */
.steps-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    position: relative;
    box-sizing: border-box;
}

.step {
    text-align: center;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 50%;
    border: 2px solid #e1e5f2;
    padding: 15px;
    transition: color 0.3s ease, transform 0.3s ease;
    position: relative;
    z-index: 1;
}

.step.active {
    color: red;
    border-color: red;
    transform: scale(1.05);
}

.step.inactive {
    color: #0f172a;
    border-color: #0f172a;
}

.step-icon {
    font-size: 24px;
    margin-bottom: 8px;
    color: currentColor;
}

.step-text {
    margin: 0;
    font-size: 14px;
    color: currentColor;
    font-weight: bold;
}

.divider {
    width: 100%;
    height: 2px;
    border: 1px solid #e1e5f2;
    display: block;
    margin-top: 20px;
}

.steps-container::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    width: 90%;
    height: 2px;
    background-color: #e1e5f2;
    z-index: 0;
}

.steps-container>.step:first-child::before {
    content: none;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
    .steps-container {
        justify-content: center;
    }

    .step {
        display: none; /* Oculta todos los pasos */
    }

    .step.active {
        display: flex; /* Muestra solo el paso activo */
        width: 130px; /* Ajusta el tamaño del círculo */
        height: 130px;
    }

    .step.active .step-icon {
        font-size: 32px; /* Aumenta el tamaño del ícono si es necesario */
    }

    .divider {
        display: none; /* Oculta la línea divisoria */
    }
}
