.table-container img {
    border-radius: 10px;
    display: block;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100px;
    min-width: 90px;
}

.table-container td {
    font-size: 13px;
    font-weight: 900;
}

.numero-de-parte {
    font-weight: 500;
}

.table-container td .button-container {
    font-size: 5px;
    display: flex;
}

.table-container td .button-container {
    font-size: 15px;
    font-weight: 900;
    padding: 10px;
}

.table-container td .button-container {
    font-size: 10px;
    font-weight: 900;
    padding: 5px;
}

.btnTables {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 20px;
}

.botonVer {
    font-size: 12px;
    padding: 2px 0px !important;
    width: 100%;
    height: 3em;
    border-color: transparent;
    background: #0f0c0c !important;
    color: white !important;
    font-weight: 900;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
}

.botonVer:hover {
    box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
    background: #dbdbdb;
    color: rgb(0, 0, 0) !important;
}

.textoSKU {
    font-size: 15px;
}

.flexPrecioStock {
    display: flex;
    justify-content: space-around;
}

#addToCart {
    font-size: 12px;
    padding: 2px 0px !important;
    width: 7em;
    height: 3em;
}

#btnIniciarSesion {
    font-size: 12px;
    padding: 2px 0px !important;
    width: 100%;
    height: 3em;
    border-color: transparent;
    margin-top: 10px;
}

.logged-buttons-categorias {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.anchoBotones {
    min-width: 10rem;
}

.flexGrid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.flexGridAddCartAddItem {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
}


.centrar__botones {
    border: 1px solid #111;
    /* padding: 5px; */
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
}

.addRemove {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.add,
.remove {
    color: #111 !important;
    display: flex;
    /* width: 27px; */
    --primary-color: #111;
    --secondary-color: transition transparent;
    --tertiary-color: #111
}

.colorBlack {
    color: #111 !important;
}

.add {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.add:hover,
.add:focus {
    background-color: var(--secondary-color) !important;
}

.remove {
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
}

.remove:hover,
.remove:focus {
    background-color: var(--secondary-color) !important;
}

/* #divAddToCart {
    margin: 0 0 0 20px;
  } */

#divAddToCart input {
    font-size: 20px;
    margin: 0;
}

.fondoBlanco {
    background: white !important;
}

.table-striped>tbody>tr:nth-of-type(2n+1)>* {
    --bs-table-accent-bg: white !important;
}

.table-hover>tbody>tr:hover>* {
    cursor: pointer;
}

.table-container {
    display: flex;
    justify-content: center;
}

.botonVerProducto {
    text-decoration: none;
    color: white;
    border-color: red;
    background-color: red;
    border-radius: 10px;
    padding: 8px;
}

.botonVerProducto:hover {
    background-color: #b82937;
    text-decoration: none;
    color: #f4f4f4;
    transition: all 1s;
}

.unlogged-buttons {
    display: flex;
}

.price-container {
    display: flex;
}

.price-container div {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 40px;
}

.dataMarcasRenderizadas {
    font-size: 12px;
}

.cards-cont {
    margin-top: 10px;
}

.svgGrilla {
    color: #a7a7a7;
    cursor: pointer;
    size: 2.5em;
}

.svgGrilla:hover {
    color: rgba(253, 50, 50, 0.671);
    transition: 0.5s ease all;
}

.activeView{
    color: red !important;
  }

/* .seeProductButtonCards {
    position: relative;
    background-color: rgb(230, 34, 77);
    border-radius: 5px;
    box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
    padding: 10px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    color: #fff;
    border: none;
    font-size: 11px;
    transition: all .3s ease-in-out;
    z-index: 1;
    overflow: hidden;
    width: 220px;

}

.seeProductButtonCards::before {
    content: "";
    background-color: rgb(248, 50, 93);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 700ms ease-in-out;
    display: inline-block;
} */

.seeProductButtonCards:hover::before {
    width: 100%;
}

.nombre-marca-card {
    color: #0077b6;
}

.nombre-producto-card {
    color: #0077b6;
    font-weight: 500;
}

.nombre-producto-card:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 550px) {
    .table-container tr {
        display: block;
    }

    .table-botonera {
        width: 100%;
    }

}