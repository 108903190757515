.flexMarcasTitulo {
    margin-top: 10px;
    text-align: center;
    font-size: 38px;
    color: #808080;
    line-height: 48px;
  }
  
  
  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-250px * 7));
              transform: translateX(calc(-250px * 7));
    }
  }
  
  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(calc(-250px * 7));
              transform: translateX(calc(-250px * 7));
    }
  }
  .slider {
    background: white;
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90%;
    margin-bottom: 10px;
  }
  .slider::before, .slider::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    -webkit-animation: scroll 40s linear infinite;
            animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 50px;
    width: 250px;
    margin-left: 10px;
  }