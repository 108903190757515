.imgHpe {
    cursor: pointer;
    padding: 10px 60px;
    transition: .3s ease-out;
    box-shadow: 5px 5px 40px rgb(129 129 129 / 49%);
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgHpe:hover {
    box-shadow: 5px 5px 40px rgb(129 129 129 / 89%);
    transform: scaleX(1.02) scaleY(1.02);
    
}

.btnEnviarIQ {
    text-align: center;
    padding: 70px 0px;
    margin: 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0 15px 60px 0 rgb(15 15 15 / 97%);
}

.btnIquote{
    background-color: rgb(1, 169, 130);
    padding: 10px 30px;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    font-weight: normal;
    border-radius: 30px;
    width: 250px;
}

.contenedorTituloBanner{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.columna1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.h2HPE {
    color: #000;
    padding-bottom: 5px;
}

@media (max-width: 768px) {
    .imgHpe {
        cursor: pointer;
        padding: 10px 20px;
        transition: .3s ease-out;
        box-shadow: 5px 5px 40px rgb(129 129 129 / 49%);
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 30px;
    }
    .contenedorTituloBanner{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .columna1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

@media (max-width: 400px) {
    .imgHpe {
        margin: 0px 8px;
    }
}