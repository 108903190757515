.divPerfil,
.divPerfil div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divPerfil h1,
.divPerfil div {
    padding: 0;
    margin: 20px 0;
}

.tablaDatos {
    width: 50%;
    margin: auto;
}

.divCompras {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 20px 0 50px 0
}

.divDetallesCompra {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 20px 0 20px 0
}

.tablaHistorialItems,
.tablaPagos,
.tablaHistorial {
    width: 60%;
    margin: 0 auto 50px;
    text-align: center;
}

.enviarComprobante {
    display: flex;
    justify-content: center;
    align-items: center;
}

#formEnviarComprobante {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
}

#formEnviarComprobante input {
    width: 500px;
    margin: 10px;
}

/*PRIMERA COLUMNA*/
.tablaHistorialItems tr td:first-child {
    text-align: start;
}

#divVerPdf {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.transferencia, .factura{
    min-width: 500px;
}

@media (max-width: 500px) {
    #formEnviarComprobante input {
        width: 50%;
        margin: 10px;
    }
    .factura, .transferencia{
        max-width: 50px!important;
    }
}