/* Estilos generales */
.mainContainer {
    max-width: 95%;
    padding: 20px;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
}

.column {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
}

.modificarUnidades {
    display: flex;
    gap: 10px;
}

.rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.priceSummary {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #525252;
}

.priceSummary h4 {
    font-size: 23px;
    color: #333;
    margin: 0;
}

.btnFinalizarCompra {
    display: flex;
    justify-content: center;
}

.btnConfirmarCompra {
    width: 90%;
    border: 2px solid #3b82f6;
    border-radius: 5px;
    background-color: #3b82f6;
    color: white;
    padding: 12px 20px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btnConfirmarCompra:hover {
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
}

.carritoVacio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    text-align: center;
    background-color: #ededed;
}

.imagenesBody {
    max-width: 100%;
    height: auto;
}

.btnVolver {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }

    .column {
        margin-bottom: 20px;
        width: 100%;
    }

    .priceSummary {
        flex-direction: column;
        align-items: flex-start;
    }

    .desktop-table {
        width: 100%;
        overflow-x: auto;
    }
}