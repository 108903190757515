.box-area {
    width: 100%;
}

.button-login-screen-new {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: red;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;

}

.custom-toast {
    margin-top: 80px;
    /* Ajusta la cantidad de desplazamiento hacia abajo que desees */
}

.button-login-screen-new2 {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background: rgb(179, 51, 51);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.changepsw-input {
    width: 90%;
}

@media only screen and (max-width : 650px) {
    .changepsw-input {
        width: 80%;
    }
}

.input-container {
    position: relative;
    display: flex;
    height: 2.8rem;
    width: 100%;
    margin-bottom: 12px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
}

.input-container input {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgb(176 190 197);
    background-color: transparent;
    padding: 0.625rem 70px 0.625rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: rgb(69 90 100);
    outline: none;
    transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-container input:focus {
    border: 1px solid rgb(236 72 153);
}

.invite-btn {
    position: absolute;
    width: 65px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    z-index: 10;
    border-radius: 4px;
    background-color: red;
    color: #fff;
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    transition: .6s ease;
}

.invite-btn:hover {
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 8px;
}

.input-container input:placeholder-shown~.invite-btn {
    pointer-events: none;
    background-color: gray;
    opacity: 0.5;
}

.divChange {
    margin-bottom: 33px;
}