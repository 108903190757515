.ofertas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
}


.oferta-map {
    margin: 5px;
    margin-left: auto;
    padding: 15px;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.oferta-card {
    width: 190px;
    height: 375px;
    background-color: #f0f0f0;
    color: #212121;
    border: none;
    border-radius: 10px;
    position: relative;
    margin: auto;
    font-family: inherit;
    display: flex;
    flex-direction: column;
}

.oferta-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.oferta-card-img {
    width: 100px;
    height: 100px;
    border: 3px solid #ffffff;
    border-radius: 100%;
    margin-top: auto;
}

.oferta-card-button {
    text-align: center;
    padding: 10px;
}

.oferta-card-button button {
    background: transparent;
    border: 2px solid #ef233c;
    color: #ef233c;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.oferta-card-button button:hover {
    background: linear-gradient(to right, #8e44ad, #c0392b);
    color: #ffffff;
    transform: scale(1.1);
}

.oferta-banner .bg-header {
    background: linear-gradient(to right, #8e44ad, #c0392b);
    padding: 1rem;
}

.oferta-banner .bg-icons {
    background-color: #ffffff;
    padding: 2rem;
}

.oferta-banner .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
}

.oferta-job {
    margin-top: 1em;
}

.oferta-job-precio {
    text-align: center;
    font-weight: bold;
    color: #00a650;
}

.oferta-job-precio-real {
    color: #6c757d;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.oferta-job-precio-real span {
    color: #6c757d;
    font-weight: bold;
}

.oferta-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
}



@media only screen and (max-width: 660px) {
    .ofertas-container {
        display: block;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        max-width: 100%;
        margin: 0 auto;
    }

}