.historial-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.historial-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.tablaHistorial {
    width: 100%;
    border-collapse: collapse;
}

.tablaHistorial th,
.tablaHistorial td {
    padding: 12px 16px;
    text-align: left;
    border: 1px solid #e0e0e0;
}

.tablaHistorial th {
    background-color: #28a745;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tablaHistorial tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ver-detalle-button {
    background-color: #218838;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 15px;
    border: none;
    transition: background-color 0.1s ease-in-out;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
}

.ver-detalle-button:hover {
    color: #fff;
    /* font-weight: 600; */
    background-color: #19692b;
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-in-out;
    /* background-color: #219ebc; */
    /* border-color: #218838; */
}

.empty-history {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
}

/* Historial.css */


.button-cell-historial {
    display: flex;
    justify-content: center;
    align-items: center;
}


.pagination-buttons-historial {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pagination-icon {
    cursor: pointer;
    font-size: 24px;
    color: red;
}

.pagination-icon.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.page-number-historial {
    font-size: 18px;
    font-weight: bold;
}