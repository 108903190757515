#divAltaUsuarios{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 10px; */
}

#divAltaUsuarios > div:first-child{
    width: 50%;
    background-color: #eee;
    padding: 20px;
}

#divAltaUsuarios > div> h4{
    text-align: center;
}

#divAltaNuevosUsuarios{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.btnAlta{
    width: auto;
    margin: auto;
}

.btnAlta > a, .btnAlta > a:hover{
    color: #fff
}

.textCenter {
    margin: 30px 20px 10px;
}

.form-container{
    display: flex;
    height: 120vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #c20000, #212529);
    padding: 10px;
}

.formulario{
    max-width: 800px;
    width: 100%;
    background: #f3f3f3;
    padding: 25px 30px;
    border-radius: 10px;
}

.form-container .title{
    font-size: 25px;
    font-weight: 600;
    position: relative;
}

.form-container .title::before{
   content: '';
   position: absolute;
   height: 3px;
   width: 30px;
   background: linear-gradient(135deg, #c20000, #9b59b6);
   left: 0;
   bottom: 0;
}

.form-container form .user-details{
   display: flex;
   flex-direction: column;
   justify-content: center;
}

form .user-details .input-box{
  margin: 20px 0 12px 0;
   /* width: calc(100% /2 -20px) ; */
   margin-bottom: 15px;
}

input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        appearance: none;
        margin: 0; 
      }
      input[type=number] { 
        -moz-appearance:textfield; 
        appearance: textfield;
    }

.user-details .input-box input{
    height: 45px;
    width: 100%;
    outline: none;
    border-radius: 10px;
    border: 1p solid #ccc;
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
}

.user-details .input-box textarea{
    height: 70px;
    width: 100%;
    outline: none;
    border-radius: 10px;
    border: 1p solid #ccc;
    padding-left: 15px;
    font-size: 16px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
     }

.user-details .input-box input:focus,
.user-details .input-box input:valid{
    border-color: #9b59b6;
}

.user-details .input-box .details{
   font-weight: 700;
   margin-bottom: 5px;
   display: block;
}

form .gender-details .gender-title{
    font-size: 20px;
    font-weight: 700;
}

form .gender-details .category{
    width: 80%;
    background: red;
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
}

 .gender-details .category label{
   display: flex;
   align-items: center;
}

.gender-details .category .dot{
    height: 18px;
    width: 18px;
    background: red;
    border-radius: 50%;
    margin-right: 10px;
    border: 5px solid transparent;
}

form .button{
    height: 45px;
    margin: 45px 0;
}

form .button input{
    height: 100%;
    width: 100%;
    outline: none;
    color: #f3f3f3;
    background: linear-gradient(135deg, #c20000, #212529);
    border: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 10px;
}

form .button input:hover{
    background: linear-gradient(-135deg, #c20000, #9b59b6);
    cursor: pointer;
}

@media (max-width: 768px) {
    #divAltaUsuarios > div:first-child{
        width: 80%;     
    }
    .reacaptcha{
        width: 100%;
    }
}

@media (max-width:584px){
    .form-container{
        max-width: 100%;
    }

    form .user-details .input-box{
        margin: 20px 0 12px 0;
        width: 100%;
    }
    form .gender-details .category{
        width: 100%;
    }
    .form-container form .user-details{
        max-height: 300px;
        overflow-y: scroll;
     }
     .user-details::-webkit-scrollbar{
        width: 0;
     }
     .form-container .title{
        font-size: 17px;
        font-weight: 600;
        position: relative;
    }
    .user-details .input-box input{
        height: 30px;
        width: 100%;
        outline: none;
        border-radius: 10px;
        border: 1p solid #ccc;
        padding-left: 15px;
        font-size: 13px;
        border-bottom-width: 2px;
        transition: all 0.3s ease;
    }
}