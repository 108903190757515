.table-container img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 75px;
  border-radius: 10px;
}

/* ... otro código ... */

.table-container td .button-container {
  font-size: 15px;
  font-weight: 900;
  padding: 10px;
}

.ver-producto-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.botonVer {
  background: #dbdbdb;
  color: #434343;
  font-weight: 900;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  border: 0px;
  padding: 3px;
  margin-right: 7px;
  flex-shrink: 0;
}

.botonVer:hover {
  box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
  background: #dbdbdb !important;
  color: #434343;
  border: 0px;
}

.btnTables {
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
  /* Remueve este margen */
  flex-shrink: 0;
}

.table-container td .button-container {
  margin-top: 10px;
  /* Ajusta este valor según tus preferencias */
}

/* ... otro código ... */


.table-container td {
  font-size: 13px;
  font-weight: 900;
}

.numero-de-parte {
  font-weight: 300;
}

.colorValor{
  font-weight: 500;
}

.precios-sin-iva-table {
  color: #5c677d;
  font-weight: 700;
}

.table-container td .button-container {
  font-size: 5px;
  display: flex;
}

.table-container td .button-container {
  font-size: 15px;
  font-weight: 900;
  padding: 10px;
}

.table-container td .button-container {
  font-size: 10px;
  font-weight: 900;
  padding: 5px;
}

/* #btnIniciarSesion{
    margin-left: 10px;
  } */

.btnTables {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 20px;
}

.botonVer a {
  color: white !important;
}

.botonVer {
  background: #dbdbdb;
  color: #434343;
  font-weight: 900;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  border: 0px;
}

.botonVer:hover {
  box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
  background: #dbdbdb !important;
  color: #434343;
  border: 0px;
}

/* .btnTables div button{
    height: 30px;
    width: 30px;
  } */

/*  #addToCart{
    background-color: #1d701d;
    color: #fff;
    font-size: 15px;
    width: 150px;
    border-radius: 10px;
    margin-top:0;
    height: 40px;
  } */

/* #divAddToCart{
    margin: 0 0 0 20px;
  } */

#divAddToCart input {
  font-size: 20px;
  margin: 0;
}

.fondoBlanco {
  background: white !important;
}

.table-striped>tbody>tr:nth-of-type(2n+1)>* {
  --bs-table-accent-bg: white !important;
}

.table-hover>tbody>tr:hover>* {
  cursor: pointer;
}

.table-container {
  display: flex;
  justify-content: center;
}

.botonVerProducto {
  text-decoration: none;
  color: white;
  border-color: red;
  background-color: red;
  border-radius: 10px;
  padding: 8px;
}

.botonVerProducto:hover {
  background-color: #b82937;
  text-decoration: none;
  color: #f4f4f4;
  transition: all 1s;
}

.unlogged-buttons {
  display: flex;
}

.logged-buttons {
  display: flex;
  justify-content: space-around;
  /* align-items: end; */
}

.maxWidth {
  max-width: 70%;
}

.price-container {
  display: flex;
}

.dataMarcasRenderizadas {
  font-size: 12px;
}

.table-name-product {
  color: #0077b6;
}

.table-name-product:hover {
  text-decoration: underline;
}

.seeProductButton {
  position: relative;
  background-color: rgb(230, 34, 77);
  border-radius: 5px;
  box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
  padding: 10px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: #fff;
  border: none;
  font-size: 13px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  z-index: 1;
  overflow: hidden;
  width: 125px;
}

.seeProductButton::before {
  content: "";
  background-color: rgb(248, 50, 93);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 700ms ease-in-out;
  display: inline-block;
}

.seeProductButton:hover::before {
  width: 100%;
}

.seeProductButtonLogged {
  position: relative;
  background-color: #7474749c;
  text-transform: uppercase;
  /* border-radius: 5px; */
  padding: 10px;
  margin-left: 19px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: #ffffff;
  border: none;
  font-size: 12px;
  transition: all .3s ease-in-out;
  width: 110px;
}

.seeProductButtonLogged:hover {
  /* content: ""; */
  background-color: rgb(121, 121, 121);
  /* width: 0; */
  /* height: 100%; */
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  /* z-index: -1; */
  transition: all .3ms ease-in-out;
  /* display: inline-block; */
}

@media only screen and (max-width: 550px) {
  .table-container tr {
    display: block;
  }

  .table-botonera {
    width: 100%;
  }

}