.ofertas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
}


.oferta-map {
    margin: 5px;
    margin-left: auto;
    padding: 15px;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.oferta-banner .bg-header {
    background: linear-gradient(to right, #8e44ad, #c0392b);
    padding: 1rem;
}

.oferta-banner .bg-icons {
    background-color: #ffffff;
    padding: 2rem;
}

.oferta-banner .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
}

.precio-span {
    color: #212121;
}

.precio-p {
    font-weight: bold;
}

.precio-p span {
    font-weight: bold;
}

.oferta-banner-container {
    margin-top: 10rem;
}



@media only screen and (max-width: 660px) {
    .ofertas-container {
        display: block;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        max-width: 100%;
        margin: 0 auto;
    }

    .oferta-map {
        margin: 10px;
        margin-left: 5.5em;
        padding: 15px;
        border-radius: 10px;
        background-color: #f0f0f0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
    }

}