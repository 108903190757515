.ofertas-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.oferta-map {
    margin: 5px;
    margin-left: auto;
    padding: 15px;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.oferta-banner .bg-header {
    background: linear-gradient(to right, #8e44ad, #c0392b);
    padding: 1rem;
}

.oferta-banner .bg-icons {
    background-color: #ffffff;
    padding: 2rem;
}

.oferta-banner .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
}

.solicitarUsuario {
    display: flex;
    justify-content: space-around;
}

.BtnHomePageUSWeb {
    padding: 15px;
    background: linear-gradient(to right, #8e44ad, #c0392b);
    border: none;
    border-radius: 27px;
    width: 400px;
    max-width: 1000px;
    font-weight: bold;
    font-size: large;
    color: white !important;
}

.BtnHomePageUSWeb:hover {
    background: linear-gradient(to left, #8e44ad, #c0392b);
    transition: 2s ease all;
}

.bg-footer-solicitar-usuario {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

@media (max-width: 600px) {
    .BtnHomePageUSWeb {
        padding: 7px;
        margin: 2px;
        background-color: red;
        border: none;
        border-radius: 27px;
        max-width: 100%;
        font-weight: bold;
        font-size: 12px;
    }

    .solicitarUsuario {
        display: flex;
        flex-direction: column;
    }

    .oferta-banner h2 {
        font-size: 18px;
        font-weight: bold;
    }

    .bg-footer-solicitar-usuario {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 660px) {
    .ofertas-container {
        display: block;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        max-width: 100%;
        margin: 0 auto;
    }
}