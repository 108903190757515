.table-container img {
  border-radius: 10px;
  display: block;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100px;
  min-width: 90px;
}

.textoPrecio {
  color: #05a500;
  font-size: 20px;
  font-weight: bold;
}

.table-container td {
  font-size: 13px;
  font-weight: 900;
}

.numero-de-parte {
  font-weight: 500;
}

.table-container td .button-container {
  font-size: 5px;
  display: flex;
}

.table-container td .button-container {
  font-size: 15px;
  font-weight: 900;
  padding: 10px;
}

.table-container td .button-container {
  font-size: 10px;
  font-weight: 900;
  padding: 5px;
}

.btnTables {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 20px;
}

.botonVer {
  font-size: 12px;
  padding: 2px 0px !important;
  width: 100%;
  height: 3em;
  border-color: transparent;
  background: #0f0c0c !important;
  color: white !important;
  font-weight: 900;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
}

.botonVer:hover {
  box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
  background: #dbdbdb;
  color: rgb(0, 0, 0) !important;
}

.textoSKU {
  font-size: 15px;
  color: #374151;

}

.texto-limited {
  display: -webkit-box; /* Usar flexbox con WebKit */
  -webkit-box-orient: vertical; /* Orientación vertical */
  line-clamp: 2; /* Limitar a 2 líneas */
  overflow: hidden; /* Ocultar el desbordamiento */
  text-overflow: ellipsis; /* Mostrar "..." al final si hay más texto */
}


.textoStock{
  color: #f18900;
  margin-top: 1em;
  font-size: 16px;
  font-weight: bold;
}

.flexPrecioStock {
  display: flex;
  /* justify-content: center;
  flex-direction: column; */
  align-items: center;
  /* margin-bottom: 15px; */
}

#addToCart {
  font-size: 12px;
  padding: 2px 0px !important;
  width: 7em;
  height: 3em;
}

#btnIniciarSesion {
  font-size: 12px;
  padding: 2px 0px !important;
  width: 100%;
  height: 3em;
  border-color: transparent;
  margin-top: 10px;
}

.logged-buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.anchoBotones {
  min-width: 10rem;
}

.flexGrid {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.flexGridAddCartAddItem {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around !important;
  min-width: 200px
}

.seeProductButtonCards,
.agregarCarrito {
  position: relative;
  background-color: #fff;
  /* border-radius: 30px; */
  /* box-shadow: 5px 10px 20px 0 rgba(0,0,0,.11); */
  padding: 10px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: #000;
  /* font-weight: 500; */
  border: 2px solid #000;
  font-size: 13px;
  transition: all .3s ease-in-out;
  z-index: 1;
  overflow: hidden;
  width: 220px;
  text-transform: uppercase;
  /* font-weight: 600; */
}

.seeProductButtonCards::before,
.agregarCarrito::before {
  content: "";
  background-color: rgb(0, 0, 0);
  color: #fff;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 300ms ease-in-out;
  display: inline-block;
}

.seeProductButtonCards:hover::before,
.agregarCarrito:hover::before {
  width: 100%;
  color: #fff;
}

.agregarCarrito:hover,
.seeProductButtonCards:hover {
  color: #fff;
}

.centrar__botones {
  border: 1px solid #111;
  /* padding: 5px; */
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
}

.addRemove {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.add,
.remove {
  color: #111 !important;
  display: flex;
  /* width: 27px; */
  --primary-color: #111;
  --secondary-color: transition transparent;
  --tertiary-color: #111
}

.colorBlack {
  color: #111 !important;
}

.add {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.add:hover,
.add:focus {
  background-color: var(--secondary-color) !important;
}

.remove {
  background-color: var(--secondary-color);
  color: var(--tertiary-color);
}

.remove:hover,
.remove:focus {
  background-color: var(--secondary-color) !important;
}

/* #divAddToCart {
  margin: 0 0 0 20px;
} */

#divAddToCart input {
  font-size: 20px;
  margin: 0;
}

.fondoBlanco {
  background: white !important;
}

.table-striped>tbody>tr:nth-of-type(2n+1)>* {
  --bs-table-accent-bg: white !important;
}

.table-hover>tbody>tr:hover>* {
  cursor: pointer;
}

.table-container {
  display: flex;
  justify-content: center;
}

.botonVerProducto {
  text-decoration: none;
  color: white;
  border-color: red;
  background-color: red;
  border-radius: 10px;
  padding: 8px;
}

.botonVerProducto:hover {
  background-color: #b82937;
  text-decoration: none;
  color: #f4f4f4;
  transition: all 1s;
}

.unlogged-buttons {
  display: flex;
}

.price-container {
  display: flex;
}

.price-container div {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 40px;
}

.dataMarcasRenderizadas {
  font-size: 12px;
}

.cards-cont {
  margin-top: 10px;
}

.svgGrilla {
  color: #a7a7a7;
  cursor: pointer;
  size: 2.5em;
}

.svgGrilla:hover {
  color: rgba(253, 50, 50, 0.671);
  transition: 0.5s ease all;
}



.nombre-marca-card {
  color: #00263a;

}

.nombre-producto-card {
  color: #00263a !important;
  font-weight: 500;
  height: 30px; /* Altura fija para el título */
  overflow: hidden; /* Ocultar el desbordamiento */
  text-overflow: ellipsis; /* Mostrar "..." al final si hay más texto */
  display: -webkit-box; /* Usar flexbox con WebKit */
  box-orient: vertical; /* Orientación vertical */
  line-clamp: 2; /* Limitar a 2 líneas */
}

.nombre-producto-card:hover {
  text-decoration: underline;
  cursor: pointer;
}

.precios-sin-iva-card {
  color: #5c677d;
  font-weight: 700;
  text-align: left;
}

@media only screen and (max-width: 550px) {
  .table-container tr {
    display: block;
  }

  .table-botonera {
    width: 100%;
  }

}