.contenEmpresa {
    width: 60%;
    margin: 0rem 1rem auto;
    padding: 2rem;
    transition: all 0.3s;
}

.contenEmpresa p {
    line-height: 1.5;
}

.centrarDiv {
    height: 300px;
}

.butnCapacitaciones {
    text-decoration: none;
    color: white;
}

.formP {
    margin-bottom: 0;
    font-weight: bold;
}

.ocultarCamposForm {
    display: none;
}

/* RMA SELECTOR */

.containerRmaButtons {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 800px) {
    .containerRmaButtons {
        padding-top: 60px;

    }

    .contAlt {
        height: 200px;
    }

}

@media only screen and (max-width: 800px) {
    .containerRmaButtons {
        flex-wrap: wrap;
    }

}

.butn2 {
    width: 80%;
    height: 60%;
    margin: 20px 0;
    background: #333;
    border-radius: 20px;
    font-size: 20px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    margin-left: 10px;
}

.butnDarkTheme {
    width: 80%;
    height: 60%;
    margin: 20px 0;
    background: #e9f4fb;
    border-radius: 20px;
    font-size: 20px;
    color: #333;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    margin-left: 10px;
}

.butn2:hover {
    background: rgba(51, 51, 51, 0.564);
    font-size: 20px;
    transition: 0.5s;
    text-decoration: none;
    color: white;
}


/* RMA MARCAS */

.containerRmaBrands {
    display: flex;
    justify-content: center;
}

.containerRmaBrandsHeight {
    height: 300px;
}

/* BOTON RMA FALLA */

.butn3 {
    margin: 20px 0;
    padding: 1%;
    border-radius: 30px;
    background: #333;
    font-size: 20px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    margin-left: 10px;
}

.butn3:hover {
    background: rgba(51, 51, 51, 0.564);
    font-size: 20px;
    transition: 0.5s;
    text-decoration: none;
    color: white;
}

/* CARDS CAPACITACIONES */

.imagenCardCapacitaciones {
    width: 100%;
    height: 250px;
    object-fit: cover;
    cursor: pointer;
}


@media only screen and (max-width: 500px) {
    .imagenCardCapacitaciones {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        cursor: pointer;
        border-radius: 0;

    }

}

.butn5 {
    width: 20%;
    height: 50%;
    margin: 20px 0;
    background: #333;
    border-radius: 20px;
    font-size: 20px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    margin-left: 10px;
}

.butn5:hover {
    background: rgba(51, 51, 51, 0.564);
    font-size: 20px;
    transition: 0.5s;
    text-decoration: none;
    color: black;
}

@media only screen and (max-width: 800px) {
    .containerRmaButtons {
        flex-wrap: wrap;
    }

    .titleCat {
        margin-bottom: 20px;
    }

    .butn5 {
        width: 30% !important;
    }

}