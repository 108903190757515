.buttonScrollTop {
    position: fixed;
    z-index: 100000;
    cursor: pointer;
    border-radius: 100%;
    opacity: 1;
    bottom: 120px;
    right: 20px;
    padding: 10px;
    transition: background-color 0.3s ease;
    width: 80px;
    height: 80px;
    z-index: 100000;
}

@media(max-width: 700px) {
    .noMuestra {
        display: none;
    }
}