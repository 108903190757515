/* formularioDenuncia.css */

/* Estilos corporativos y modernos para el formulario */
.formulario-denuncia {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
  }
  
  .formulario-denuncia h2 {
    margin-top: 0;
    color: #007BFF;
  }
  
  .formulario-denuncia label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .formulario-denuncia input[type="text"],
  .formulario-denuncia input[type="email"],
  .formulario-denuncia input[type="tel"],
  .formulario-denuncia textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formulario-denuncia input[type="checkbox"],
  .formulario-denuncia input[type="radio"] {
    margin-right: 5px;
  }
  
  .formulario-denuncia div {
    margin-bottom: 10px;
  }
  
  .formulario-denuncia button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .formulario-denuncia button:hover {
    background-color: #0056b3;
  }
  
  /* Estilos adicionales para elementos específicos */
  .formulario-denuncia input[type="checkbox"] + label,
  .formulario-denuncia input[type="radio"] + label {
    color: #555;
  }
  
  .formulario-denuncia input[type="checkbox"] + label::before,
  .formulario-denuncia input[type="radio"] + label::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    border: 1px solid #007BFF;
    border-radius: 2px;
    vertical-align: middle;
    background-color: #fff;
  }
  
  .formulario-denuncia input[type="checkbox"]:checked + label::before,
  .formulario-denuncia input[type="radio"]:checked + label::before {
    background-color: #007BFF;
  }
  
  .formulario-denuncia input[type="checkbox"]:focus + label::before,
  .formulario-denuncia input[type="radio"]:focus + label::before {
    border-color: #0056b3;
  }
  
  .formulario-denuncia input[type="text"]:focus,
  .formulario-denuncia input[type="email"]:focus,
  .formulario-denuncia input[type="tel"]:focus,
  .formulario-denuncia textarea:focus {
    outline: none;
    border-color: #007BFF;
    box-shadow: 0 0 4px #007BFF;
  }
  
  