.contenedor-registro {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

.form-registro {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group-registro {
    display: flex;
    flex-direction: column;
}

.form-registro .form-group-registro label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-registro .form-group-registro input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.form-group-registro button {
    padding: 10px;
    background-color: #d00000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-group-registro button:hover {
    background-color: #0d1b2a;
}

.captchaCont {
    display: flex;
    justify-content: center;
}

