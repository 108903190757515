.BtnHomePageWebCat {
    padding: 15px;
    background-color: transparent;
    color: #e11d48;
    border: 2px solid #e11d48;
    border-radius: 27px;
    width: 600px;
    max-width: 1000px;
    font-weight: bold;
    font-size: large;
    text-align: center;
    display: block;
    margin: 0 auto;
    transition: 200ms ease all;
}

.BtnHomePageWebCat:hover {
    background-color: #e11d48;
    color: white;
    border-color: #e11d48;
    transition: 400ms ease all;
}


@media (max-width: 600px) {
    .BtnHomePageWebCat {
        padding: 8px;
        background-color: transparent;
        border: 2px solid #e11d48;
        border-radius: 27px;
        max-width: 80%;
        font-weight: bold;
        font-size: 10px;
        margin-bottom: 10px;
        display: block;
        text-align: center;
        margin-left: auto;
        color: #e11d48;
        margin-right: auto;
        transition: all 0.3s ease;
    }

    .flexBtns {
        display: flex;
        flex-direction: column;
    }

    .BtnHomePageWebCat:hover {
        border-color: transparent;
        border: 2px solid #e11d48;
        background: linear-gradient(90deg, #e11d48 0%, white 100%);
        color: white;
    }
}