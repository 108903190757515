:root {
    --primary: #c20000;
}

.bordeBarraNav {
    border-bottom: 1px solid #e11d48;
}

#navbarScrollingDropdown,
#navbarSinScroll {
    color: white;
    align-content: flex-end;
    font-size: 01em;
    font-weight: 500;
}

#navbarScrollingDropdownMarcas {
    color: white !important;
    font-size: 0.8em;
}

.dropdown-menu.show {
    background: #212529;
}

.dropdown-item {
    color: white !important;
    font-size: 0.9em;
}

.dropdown-item:hover {
    background: red !important;
}

.dropdown-item {
    background-color: transparent;
}

.dropdown-item:focus {
    background-color: transparent;
}


.dropdown-item-categorias {
    color: white !important;
    font-size: 0.9em;
    margin-right: 0.5em;
}

.dropdown-item-categorias:hover {
    background: red !important;
}

.dropdown-item-categorias {
    background-color: transparent;
}

.dropdown-item-categorias:focus {
    background-color: transparent;
}


.navbar-light .navbar-toggler {
    background-color: white;
}

.navbar-toggler.collapsed {
    color: white;
}

.element.style {
    height: 100vh;
}

.irAlPerfil:hover {
    background-color: #198754a1 !important;
    border-color: transparent !important;
}

#navbarScrollingDropdown:hover,
#navbarSinScroll:hover {
    color: var(--primary);
    transform: scale(1.1);
    text-decoration: underline;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}


#navbarScrollingDropdownMarcas:hover {
    background-color: var(--primary);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}



.lightNav {
    background-color: rgb(33, 37, 41)
}

.darkNav {
    background-color: #3d3d3d
}


.mod {
    margin-left: -15px;
}

.dominicana {
    font-size: 15px;
}

.setBanderas {
    padding-right: 10px;
    width: 25%;
    height: 30%;
}

.staffEdit {
    padding-left: 7px;
}

.setlogo {
    width: 150px;
}


.btnLogueado {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 13px;
    z-index: 10;
}


.btnLogueado span {
    color: #fff;
    align-self: center;
}

.btnLogueado2 {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    flex-wrap: wrap;
}

.btnLogueado2 div,
.btnLogueado2 span {
    margin: 0 10px;
}

.dollarValue {
    color: #fff;
    font-weight: bold;
    font-size: 11px;
}

.ofertas-navlink {
    background: linear-gradient(135deg, #ff4e50, #ff5e62, #d81e5b, #6a1b9a);
    color: white !important;
    font-weight: bold;
    padding: 5px 10px;
    text-decoration: none;
    margin-left: 1em;
    border-radius: 20px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



@media (min-width: 769px) {
    .ofertas-navlink:hover {
        transform: translateY(2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
}




@media (min-width: 300px) and (max-width: 1000px) {
    .setBanderas {
        width: 5%;
    }
}

@media (min-width: 300px) and (max-width: 500px) {
    .setBanderas {
        width: 15%;
    }
}

body.swal2-shown>[aria-hidden="true"] {
    transition: 0.1s filter;
    filter: blur(10px);
}

.contenedorLogin {
    display: flex;
    align-items: center;
    justify-content: center;
}

.botonLoguearse {
    width: 100%;
    margin-left: 10px;
}

.barritaForm {
    margin: 10px;
}

@media(max-width:992px) {
    .botonLoguearse {
        width: 100px;
        margin-left: 10px;
    }

    .btnlogincontain {
        display: flex;
        justify-content: center;
    }

    .mobileAlign {
        text-align: center;
    }

    .btnloginContMobile {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .mobileBtnLogin {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btnLogueado2 {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .ofertas-navlink {
            text-align: center;
            width: 50%;
            margin: 0;
        }

        .ofertas-navlink-container {
            display: flex;
            justify-content: center;
        }
    }

}

@media(min-width:992px) and (max-width:1570px) {
    .navbar-expand-lg .navbar-collapse {
        flex-wrap: wrap;
    }
}