.flex-ofertas-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    margin-top: 1em;
}

.flex-ofertas-container .divider {
    width: 5em;
    height: 3px;
    background-color: #e5383b;
    margin-top: 5px;
}

.flex-ofertas-container .selected {
    color: #FF5733;
}

.flex-ofertas-icons:hover {
    cursor: pointer;
}