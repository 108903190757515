.card {
  background-color: rgb(241, 241, 241);
}

.editC {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.absolute {
  position: relative;
}

.imgProd {
  /* width: 50%; */
  max-width: 80%;
}

.logosb {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}

.spanStock {
  background-color: rgb(241, 241, 241);
  color: rgb(202, 132, 3);
}

.Descripcion {
  color: gray;
  margin-top: -20px;
  font-size: 12px;
}

.botonInfo {
  background-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  ;
  border-radius: 10px !important;
}

.botonInfo:hover {
  background-color: #b82937 !important;
  color: rgb(53, 52, 52) !important;
  transition: all 1s;
}

.botonLogin {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  ;
  border-radius: 10px !important;
  margin-left: 60px;
}

.botonCompra {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  ;
  border-radius: 10px !important;
  margin-left: 30px;
}

.flexContainer {
  box-shadow: 15px 17px 13px -11px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 15px 17px 13px -11px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 15px 17px 13px -11px rgba(0, 0, 0, 0.9);
}

.spanIva {
  background-color: white;
  color: gray;
  font-size: 10px;
}

.imgProd {
  max-height: 60%;
}

.contenedorCard {
  position: relative;
  bottom: 0px;
}

.inputbox {
  position: relative;
  width: 196px;
}

.inputbox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
}

.inputbox span {
  position: absolute;
  left: 0;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

.inputbox input:valid~span,
.inputbox input:focus~span {
  color: #212529;
  transform: translateX(-10px) translateY(-34px);
  font-size: 0, 75em;
}

.inputbox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #f5f5f5;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputbox input:valid~i,
.inputbox input:focus~i {
  height: 44px;
}

.page-item .page-link {
  color: #201b1b;
}

.active .page-link {
  background: #5e5252 !important;
  color: #fff;
}

.api-map-container {
  width: 100%;
}

.taleShadow {
  box-shadow: 0 20px 50px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 23px;
  width: 100%;
}


/* DIV FILTRAR PRECIOS */
#divFiltrosBusqueda {
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

#divFiltroMinMax {
  justify-content: center;
}

#divFiltroMinMax,
#divFiltroAscDes,
#divFiltroBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#divFiltroMinMax>h6,
#divFiltroAscDes>h6 {
  margin: 0 10px;
}

#divFiltroMinMax>input,
#btnBuscarMinMax {
  width: 20%;
  margin: 0 10px;
}

#btnBuscarMinMax {
  height: 30px;
  padding: 0 10px;
  background-color: #0088cc;

}

#divFiltroAscDes>select {
  width: 50%;
  height: 30px;
}

#divFiltroBtn>button {
  margin: 0 10px;
}

.card-container {
  width: calc(100% - 15px);
  /* Ajusta este valor según sea necesario */
}

.activeView{
  color: red !important;
}